import { initializeApp } from 'firebase/app'
import { getAuth, updatePassword } from 'firebase/auth'
import { getDatabase, ref, onValue } from 'firebase/database'
import { getFirestore, doc, setDoc, collection, getDocs } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
const firebaseConfig = {
  apiKey: 'AIzaSyAxAJJhLKEkFlO-Drhl5SZf2f_vn-qPNa4',
  authDomain: 'flutterhub-5588f.firebaseapp.com',
  projectId: 'flutterhub-5588f',
  storageBucket: 'flutterhub-5588f.appspot.com',
  messagingSenderId: '689043158864',
  appId: '1:689043158864:web:bf592955b9d90490225e55',
  measurementId: 'G-DQV694KS2H',
}
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const database = getDatabase(app)
const firestore = getFirestore(app)
const storage = getStorage(app)
const projectId = firebaseConfig.projectId
export { app, auth, database, firestore, storage, updatePassword, projectId }
