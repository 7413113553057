import { firestore } from './configs/FirebaseConfig'
import { onSnapshot, getDocs, collection } from 'firebase/firestore'
// Function to fetch site settings

export const fetchSiteSettings = (callback) => {
  const siteSettingsCollection = collection(firestore, 'Settings')

  const unsubscribe = onSnapshot(siteSettingsCollection, (querySnapshot) => {
    const settingsData = []
    querySnapshot.forEach((doc) => {
      settingsData.push({ id: doc.id, ...doc.data() })
    })

    callback(settingsData)
  })

  return unsubscribe
}
